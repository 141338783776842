<template>
  <div class="container">
    <!-- 顶部navbar -->
    <div class="navbar">
      <div class="content">
        <img
          src="../assets/img/logo.png"
          alt=""
          @click="$router.push('/home')"
          style="cursor: pointer"
        />
        <div class="navbar-right">
          <div class="navbar-item">
            <a href="javascript:;">下载APP</a>
            <img
              src="../assets/img/xiala.png"
              alt=""
              @mouseenter="isShow = true"
              @mouseleave="isShow = false"
            />
            <img
              v-show="isShow"
              class="download-qr"
              :src="net + about_service.app_download"
              alt=""
              style="background: #fff; padding: 2px"
            />
          </div>
          <div class="navbar-item">
            <router-link to="/aboutUs">关于我们</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/login">登录</router-link>
          </div>
          <div class="navbar-item">
            <router-link to="/register">注册</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="banner">
      <div class="content">
        <el-carousel trigger="click" height="800px">
          <el-carousel-item v-for="(item, index) in banner_imgs" :key="index">
            <a :href="item.link" target="_blank">
              <img v-lazy="net + item.pic" alt="" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 介绍 -->
    <div class="intro-section">
      <div class="content">
        <!-- 公告 -->
        <div class="notice">
          <div class="notice-content">
            <div class="notice-left">
              <div class="notice-title">
                <img src="../assets/img/notice.png" alt="" />
                <span>最新公告</span>
              </div>
              <div class="notice-text">
                <el-carousel height="40px" direction="vertical">
                  <el-carousel-item
                    v-for="(item, index) in notices"
                    :key="index"
                  >
                    <span
                      class="medium"
                      @click="
                        $router.push({
                          path: '/noticeDetail',
                          query: { id: item.id },
                        })
                      "
                      >{{ item.title }}</span
                    >
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
            <router-link to="allNotice" class="more">
              <span>查看更多</span>
              <img src="../assets/img/next.png" alt="" />
            </router-link>
          </div>
        </div>
        <div class="about">
          <video
            :src="net + about_service.video"
            controls="controls"
            poster="../assets/img/poster.png"
          ></video>
          <div class="about-text">
            <h4>关于我们</h4>
            <p>{{ about_service.content }}</p>
          </div>
        </div>
        <!-- 服务 -->
        <div class="service">
          <div class="service-item">
            <div class="item-card">
              <img
                
                :src="net + about_service.pic_a"
                alt=""
              />
            </div>
            <div class="item-card">
              <img
                
                :src="net + about_service.pic_b"
                alt=""
              />
            </div>
          </div>
          <div class="service-item" style="margin-top: 120px">
            <div class="item-card">
              <img
                
                :src="net + about_service.pic_c"
                alt=""
              />
            </div>
            <div class="item-card">
              <img
                
                :src="net + about_service.pic_d"
                alt=""
              />
            </div>
          </div>
          <div class="service-item" style="margin-right: 0; margin-top: 180px">
            <h4 class="service-title">我们提供的服务</h4>
            <div class="item-card">
              <img
                
                :src="net + about_service.pic_e"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footer-btn">
        <h4>让天下没有难做的投资</h4>
        <button type="button" @click="$router.push('/customized')">
          申请投资
        </button>
      </div>
      <div class="footer-contact">
        <div class="content">
          <div class="footer-logo">
            <img src="../assets/img/logo-footer.png" alt="" />
          </div>
          <div class="contact-us">
            <h4>联系我们</h4>
            <p>客服电话：{{ about_service.service_phone }}</p>
            <p>客服邮箱：{{ about_service.email }}</p>
          </div>
          <div class="honours">
            <h4>企业荣誉</h4>
            <div class="honour-img">
              <a
                :href="item.link"
                target="_blank"
                v-for="(item, index) in honor"
                :key="index"
              >
                <img :src="net + item.pic" alt="" />
              </a>
            </div>
          </div>
          <div class="download">
            <h4>下载</h4>
            <div class="download-qr">
              <div class="qr-item">
                <img :src="net + about_service.app_download" alt="" />
                <h5>APP下载</h5>
              </div>
              <div class="qr-item">
                <img :src="net + about_service.wechat" alt="" />
                <h5>官方微信</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          ©copyright 2021-永远 汇成国际 hcgj.co 版权所有
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >辽ICP备2021009245号-2</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getHome, getHonor, getNotice } from "@network/home";
export default {
  name: "Home",
  data() {
    return {
      setting: {
        title: "",
        keywords: "",
        description: "",
      },
      net: BASE_URL,
      downloadData: {
        url: window.location.href,
      },
      about_service: {}, //关于和服务
      honor: [],
      banner_imgs: [],
      isShow: false,
      notices: [], //公告
    };
  },

  created() {
    this.getHome();
    this.getHonor();
    this.getNotice();
    if (window.localStorage.getItem("token")) {
      this.$router.replace("/homeLogged");
    }
  },

  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },

  components: {},

  methods: {
    bannerTo(type) {
      if (type == 0) {
        return false;
      } else if (type == 1) {
        this.$router.push("/joinRaise");
      } else if (type == 2) {
        this.$router.push("/customized");
      }
    },
    //公告
    getNotice() {
      getNotice(1, 2).then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        }
        this.notices = res.data;
      });
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });
          return false;
        } else {
          this.about_service = res.data.homePage;
          this.banner_imgs = res.data.carousel;
          this.setting.title = res.data.homePage.website_name;
          this.setting.keywords = res.data.homePage.website_keywords;
          this.setting.description = res.data.homePage.website_desc;
        }
      });
    },
    //企业荣誉
    getHonor() {
      getHonor().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        } else {
          this.honor = res.data;
        }
      });
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
@import "../assets/css/home.less";
</style>